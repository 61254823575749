import React from "react";
import Layout from "../../components/layout-main";
import { Flex, Grid } from "theme-ui";

/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const fullName = `Jimmy Gamonet de los Heros`;
  const zoomUrl = "";
  const storiesRecordUrl = "https://stories.afterword.com/fu9nu8nbe";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rse9j6pzdgmxdzcjnm8rjoadbhom9oyzvclucmsq";
  const imgStyles = { padding: ["1em 0", "3em 0"] };

  return (
    <>
      <Layout
        location={location}
        pageTitle={"Remembering " + fullName}
        hideLinks={true}
        hideSubscribe={true}
        buttonOverride={true && zoomUrl}
        noIndex={true}
      >
        <section sx={{ backgroundColor: "#f0f0f0", textAlign: "center" }}>
          <div className="aw-content">
            <h1
              sx={{
                fontWeight: "300",
                margin: 0,
                textAlign: "center",
                fontSize: [
                  "calc((2.8 - 1) * 0.4vw + 1em)",
                  "calc((2.8 - 1) * 1.2vw + 1em)",
                ],
              }}
            >
              Jimmy Gamonet de los Heros's <i>Coda</i>
            </h1>
            <video
              controls
              allowFullScreen
              loop
              name="media"
              poster="/images/people/jimmy-splash-coda.png"
              sx={{ width: "100%", "&:hover": { cursor: "pointer" } }}
            >
              <source
                src="https://storage.googleapis.com/afterword-static/recordings/JG-Coda.mp4"
                type="video/mp4"
              />
            </video>
            <h2 sx={{ fontWeight: "300", fontSize: [1, 3, 4] }}>
              Sunday, March 21st, 2p.m. USA EST | 1p.m. Lima | 6p.m. London |
              7p.m. Geneva
              <br />
              Domingo, 21 de marzo, 2p.m. EST de EE. UU. | 1p.m. Lima | 6p.m.
              Londres | 7p.m. Ginebra
            </h2>
            {/* <a href={zoomUrl} target="_blank" rel="noreferrer">
              <Button
                sx={{
                  padding: ["12px 18px", "18px 24px"],
                  fontSize: ["16px", "24px"],
                  height: "auto",
                }}
              >
                Join Virtually with Zoom
                <br />
                Únete Aquí con Zoom
              </Button>
            </a> */}
            <p sx={{ padding: "1em 0", fontSize: ["14px", "16px"] }}>
              <b>
                Jimmy’s <i>Coda</i> is a one-hour memorial celebration of his
                life, his body of work and the legacy he leaves behind.
              </b>{" "}
              After the celebration join us for an opportunity to listen and
              share more memories with Jimmy's family and friends (attendance
              optional).
            </p>
            <p
              sx={{
                padding: "1em 0",
                paddingTop: "0",
                fontSize: ["14px", "16px"],
              }}
            >
              <b>
                <i>La Coda de Jimmy</i> es una celebración de una hora,
                conmemorando su vida, su trabajo y su legado.
              </b>{" "}
              Después de la celebración, quédese en línea para escuchar y
              compartir recuerdos con la familia y los amigos de Jimmy
              (asistencia opcional).
            </p>
          </div>
        </section>

      
        <section className="aw-gray">
          <div className="aw-content aw-content-extended">
            <Grid
              gap={4}
              columns={["none", "1fr 1fr"]}
              sx={{ alignItems: "center" }}
            >
              <div sx={imgStyles}>
                <img
                  sx={{ maxWidth: "100%" }}
                  src="https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F6WuIWequA3mfpLvOMtwN%2Ff3ca4ec-81f-7350-a356-1a6d100153?alt=media&token=10c895ee-f7dd-42ae-b140-ffe3171b35ed"
                ></img>
              </div>

              <h2
                sx={{
                  fontWeight: "300",
                  textAlign: "right",
                  fontSize: ["24px", "36px"],
                }}
              >
                “Gamonet shows a talent full of flair and spice as well as an
                instinctive feeling for dancerly rhetoric.”
                <br />
                <img
                  sx={{ maxWidth: "80%" }}
                  src="/images/people/washington-post.png"
                ></img>
              </h2>
            </Grid>
          </div>
        </section>
        <section className="aw-gray">
          <div className="aw-content aw-content-extended">
            <Grid
              gap={4}
              columns={["none", "1fr 1fr"]}
              sx={{ alignItems: "center", gridAutoFlow: ["none", "dense"] }}
            >
              <div
                sx={{
                  ...imgStyles,
                  textAlign: "center",
                  gridColumn: ["1", "2"],
                }}
              >
                <img
                  sx={{ maxWidth: "100%" }}
                  src="https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F6WuIWequA3mfpLvOMtwN%2F4b84b7-8648-45b-3b5-b34f1233e4?alt=media&token=eeac08e7-c96c-49fb-a1ca-4e19005942ab"
                ></img>
              </div>
              <h3
                sx={{
                  fontSize: ["36px"],
                  fontWeight: "300",
                }}
              >
                “That bright energy was more happily apparent in two ballets by
                Jimmy Gamonet de los Heros…a man who loves big, sweeping motion
                and geniality”
                <br />
                <img
                  sx={{ maxWidth: "80%" }}
                  src="/images/people/nyt.png"
                ></img>
              </h3>
            </Grid>
          </div>
        </section>

        <section className="aw-gray">
          <div className="aw-content aw-content-extended">
            <Grid
              gap={4}
              columns={["none", "1fr 1fr"]}
              sx={{ alignItems: "center" }}
            >
              <div sx={imgStyles}>
                <img
                  sx={{ maxWidth: "80%" }}
                  src="https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F6WuIWequA3mfpLvOMtwN%2Fbda52-6f43-a752-2220-8476086504?alt=media&token=86062283-ddb5-4bdd-b546-aa8a85bdb00f"
                ></img>
              </div>

              <Flex sx={{ flexDirection: "column" }}>
                <h1
                  sx={{
                    fontWeight: "300",
                    fontSize: ["36px"],
                    textAlign: "center",
                  }}
                >
                  “Gamonet is a gifted classical choreographer - one of the very
                  few.”
                </h1>
                <img
                  sx={{ maxWidth: "100%" }}
                  src="/images/people/boston-globe.png"
                ></img>
              </Flex>
            </Grid>

            {/* <div sx={{ ... imgStyles, textAlign: "center" }}>
              <img sx={{ maxWidth: "75%" }} src="https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F6WuIWequA3mfpLvOMtwN%2Fcfd2b-0f34-26ed-d83d-25e6dc3672a?alt=media&token=87ff89f3-4bee-49ea-aed3-3e7ea88ca821"></img>
            </div> */}
          </div>
        </section>
        <section className="aw-gray">
          <div className="aw-content aw-content-extended">
            <Grid
              gap={4}
              columns={["none", "1fr 1fr"]}
              sx={{ alignItems: "center", gridAutoFlow: ["none", "dense"] }}
            >
              <div
                sx={{
                  ...imgStyles,
                  textAlign: "center",
                  gridColumn: ["1", "2"],
                }}
              >
                <img
                  sx={{ maxWidth: "100%" }}
                  src="https://firebasestorage.googleapis.com/v0/b/afterword-prod.appspot.com/o/humans%2F6WuIWequA3mfpLvOMtwN%2Fdbf6f3-47b0-3fb5-e8c0-cb6cd637fd?alt=media&token=516a3b0b-29d5-40fd-880f-00f75f7df6e7"
                ></img>
              </div>
              <Flex
                sx={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <h3
                  sx={{
                    fontSize: "24px",
                    fontWeight: "300",
                  }}
                >
                  “Miami City Ballet danced a whole evening of resident
                  choreographer Jimmy Gamonet De Los Heros' ballets on Friday
                  night. If the focus was the premiere of his Partita, set to
                  various Vivaldi concerti, the real revelation was how rich and
                  varied a dance experience Gamonet can provide.”
                </h3>
                <img
                  sx={{ maxWidth: "60%" }}
                  src="/images/people/miami-herald.png"
                ></img>
              </Flex>
            </Grid>
          </div>
        </section>

        <section>
          <div className="aw-content">
            <h2>Jimmy's Obituaries</h2>
            <p>
              <a
                href="https://www.miamiherald.com/news/local/obituaries/article249568088.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Miami Herald: Choreographer Jimmy Gamonet de los Heros, who
                helped shape ballet in Miami, dies at 63
              </a>
            </p>
            <p>
              <a
                href="https://elpais.com/cultura/2021-03-03/muere-jimmy-gamonet-de-los-heros-director-del-ballet-nacional-de-peru.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                El País: Muere Jimmy Gamonet de los Heros, director del Ballet
                Nacional de Perú
              </a>
            </p>
            <p>
              <a
                href="https://elcomercio.pe/luces/teatro/murio-jimmy-gamonet-de-los-heros-director-artistico-del-ballet-nacional-del-peru-ministerio-de-cultura-noticia/"
                target="_blank"
                rel="noopener noreferrer"
              >
                El Comercio: Murió Jimmy Gamonet de los Heros, director
                artístico del Ballet Nacional del Perú
              </a>
            </p>

            <h2>Honor Jimmy’s Legacy</h2>
            <p>
              To further pay tribute to his legacy please consider a gift to the{" "}
              <b>
                Jimmy Gamonet de los Heros’s Performing Arts Fund at the Miami
                Foundation.
              </b>{" "}
              This fund is dedicated to providing financial assistance to young
              talent with a passion for artistic expression, especially ballet.
            </p>
            <p>
              Checks can be made payable to The Miami Foundation c/o Jimmy
              Gamonet de los Heros’s Performing Arts Fund and mailed to The
              Miami Foundation, 40 NW Third Street, Suite 305, Miami, FL 33128.
              Or donate via credit card{" "}
              <a
                href="http://give.miamifoundation.org/jimmygamonet"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>

            <p>
              A manera de honrar la memoria de Jimmy considere hacer una
              donación al{" "}
              <b>
                Fondo a nombre de Jimmy Gamonet de Los Heros en la Fundación
                Miami
              </b>
              . El fondo se dedica a proveer asistencia financiera a jóvenes
              talentos con una pasión por la expresión artística, especialmente
              en ballet.
            </p>

            <p>
              Los cheques deben hacerse a nombre de The Miami Foundation c/o
              Jimmy Gamonet de los Heros’ Performing Arts Fund y deben ser
              enviados por correo a The Miami Foundation, 40 NW Third Street,
              Suite 305, Miami, FL 33128. Para donar por tarjeta de crédito
              entre{" "}
              <a
                href="http://give.miamifoundation.org/jimmygamonet"
                target="_blank"
                rel="noopener noreferrer"
              >
                aqui
              </a>
              .
            </p>

            <h2>Music from Jimmy's Family</h2>
            <iframe
              style={{ border: "0", width: "100%", height: "120px" }}
              src="https://bandcamp.com/EmbeddedPlayer/album=2459630372/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/"
              seamless
            >
              <a href="https://oblique-live.bandcamp.com/album/metamorphosis">
                MetaMorphosis by Oblique
              </a>
            </iframe>

            <h2>Share a Story</h2>
            <p sx={{ display: ["block", "block", "none"] }}>
              To share a memory, click play followed by Video, Audio, or Text,
              depending on how you want to share
            </p>
            <iframe
              title="Share a story"
              src={storiesRecordUrl.replace(
                "//stories.afterword.co/",
                "//videoask.com/"
              )}
              allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
              width="100%"
              height="600px"
              style={{ border: "none", borderRadius: "24px" }}
            ></iframe>
          </div>
        </section>

        <section>
          <div className="aw-content">
            <h2>Shared Stories</h2>
            <iframe
              title="memory-book"
              src={storiesSubmissionsUrl.replace(
                "//stories.afterword.co/",
                "//videoask.com/"
              )}
              allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
              width="100%"
              height="600px"
              style={{ border: "none", borderRadius: "24px" }}
            ></iframe>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default ThisPage;
